import { useState, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import { useNavigate } from 'react-router-dom';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import Avatar from '@mui/material/Avatar';

export default function SiteRules({siteId, userId, isDrivingToday, setIsDrivingToday, signIn, signOut}) {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [response, error2,loading, axiosFetch] = useAxiosFunction();
  const [result, setResult] = useState(false);

  useLayoutEffect(() => {
    if(result){
      submitAction();
    }
    
  }, [result])

  const nextPage = () =>{
    //if sign in navigate to driving question, else navigate to site page
    //record attendance sign out
      submit();
    

  }

  const submit = async e => {
    const bodyObj = JSON.stringify({
      "userId":userId,
      "siteId":siteId,
      "isDriving":isDrivingToday,
      "signIn":signIn,
      "signOut":signOut,
      "signedBy":userId
    });

    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/recordAttendance',
        requestConfig: {
          headers: {
            'Content-Type': 'application/json',
          },
          data: bodyObj
        }

      })
    } catch (err) {
      console.log(err);

    }
    setResult(true);
  }

  function submitAction() {
    console.log("RESPONSE: "+response)
    if (response.status === 200) {




      
      navigate("/success");
    }
  }


  return (
    <div>
      <Box className={styles.questionH1Top} >
        <h1>Site Rules:</h1>
        </Box>
        <Box className={styles.list} >
          <ul className={styles.listul}>
            <li>You must have received and signed your Site-Specific Risk Assessment / Safe System of Work.</li>
            <li>Check the site Traffic Management Plan and Hazard board each day and always follow the pedestrian routes.</li>
            <li>Standard PPE to be worn at all times.</li>
            <img className={styles.listimg} src={process.env.PUBLIC_URL + '/siteRules.jpeg'}></img>
            <li>Other PPE worn as required for your work.</li>
            <li>Help prevent trip hazards, keep your work area tidy, use the skips provided.</li>
            <li>Plant and equipment to be used by trained and authorised persons only.</li>
            <li className={styles.bold}>If you see something that is not safe - let the Site Manager know immediately.</li>
          </ul>
        </Box>


        <Box className={btnStyles.navbuttons} >
              <Button className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Sign In</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Sign In' onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
        
    </div>
  );
}

