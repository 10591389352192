import axios from 'axios'
const TEST_URL = 'http://localhost:8080'
const TEST_ENV_URL = "http://springboottesting.j.layershift.co.uk";
const LIVE_URL = "https://twsa.hopedrew.co.uk/"
export default axios.create({
    baseURL:LIVE_URL,
    headers:{
        'Content-Type': 'application/json'
    }
});

