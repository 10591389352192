import { useState, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import { useNavigate } from 'react-router-dom';

export default function ActionPage({signIn, setSignIn, signOut, setSignOut, siteId, userId}) {

  const navigate = useNavigate();
  const [response, error2,loading, axiosFetch] = useAxiosFunction();
  const [result, setResult] = useState(false);

  useLayoutEffect(() => {
    if(result){
      submitAction();
    }
    
  }, [result])

  const nextPage = (res) =>{
    //if sign in navigate to driving question, else navigate to site page

    if(res === "signIn"){
      setSignIn(true);
      setSignOut(false);
      navigate("/driving");

    }
    else if(res === "signOut"){
      setSignOut(true);
      setSignIn(false);

      //record attendance sign out
      submit();
    }

  }

  const submit = async e => {
    const bodyObj = JSON.stringify({
      "userId":userId,
      "siteId":siteId,
      "isDriving":false,
      "signIn":false,
      "signOut":true,
      "signedBy":userId
    });

    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/recordAttendance',
        requestConfig: {
          headers: {
            'Content-Type': 'application/json',
          },
          data: bodyObj
        }

      })
    } catch (err) {
      console.log(err);

    }
    setResult(true);
  }

  function submitAction() {
    console.log("RESPONSE: "+response)
    if (response.status === 200) {
      navigate("/success");
    }
  }


  return (
    <div>
      <Box className={styles.questionH1Top} >
        <h1>Select your action:</h1>
        </Box>
        <Box className={btnStyles.buttonContainer} >
            <Button className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("signIn")} 
             >Sign In</Button>
            <Button className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("signOut")} 
             >Sign Out</Button>

      </Box>
        
    </div>
  );
}

